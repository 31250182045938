<template>
    <div class="alert">
        <span v-html="this.text"></span>
    </div>
</template>

<script>

export default {
    name: 'AlertItem',
    props: ['text'],
    mounted(){
        // if(this.text != ''){
        //     let div = document.querySelector('.alert');
        //     setTimeout(() => {
        //         div.remove();
        //     }, 3000);
        // }
    }
}
</script>

<style scoped>
.alert {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: auto;
    height: auto;
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 999;
    box-shadow: 0 1px 6px rgba(0,0,0,.15);
}
</style>