import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    site: {
      logo: '',
      logo_footer: '',
      header: '',
      color1: '',
      color2: '',
      ip: '',
      port: 0,
      code: '',
      maintenance: false
    },
    user: {
      id: 0,
      user: '',
      logged: false
    },
    comments: [],
    urlAPI: 'https://api.pauloferraco.com.br/v3'
  },
  getters: {
  },
  mutations: {
    init(store, data){
      store.site = data
      let root = document.documentElement;
      root.style.setProperty("--color1", data.color1)
      root.style.setProperty("--color2", data.color2)
      root.style.setProperty("--color3", data.color3)

      var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href =  data.favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
      
      if(data.body != ''){
        document.getElementsByTagName('body')[0].style.backgroundImage = 'url('+data.body+')'
      }
      document.getElementsByTagName('body')[0].style.backgroundColor = data.cor_body
    },
    changeUser(state, data){
      state.user = {
        id: data.id,
        user: data.user,
        logged: data.logged
      }
    },
    changeCom(state, data){
      state.comments = data
    }
  },
  actions: {
    async initSys({commit}){
      const req = await axios.get('async/init')
      commit('init', req.data)
    },
    async initUser({commit}) {
      const req = await axios.get('page/logged')
      commit('changeUser', req.data)
    },
    async comments({commit}, data){
      const req = await axios.post('page/comments', {
				id: data.id,
				type: data.type
			});
			commit('changeCom', req.data)
    }
  },
  modules: {
  }
})
