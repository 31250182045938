<template>
	<div class="item">
		<div class="top">
			<div class="title"><span>Destaques</span></div>
		</div>
		<div class="content" v-show="dests.length > 0">
			<div class="box" v-for="(d, i) in dests" :key="i" :class="{'right': i == 1}">
				<div class="avatar" :style="{backgroundImage: 'url(https://imager.bubblet.com.br/?user='+d.name+'&action=&gesture=std&direction=2&head_direction=3&headonly=false&size=b&img_format=&dance=&effect=&frame_num=30)'}"></div>
				<div class="text">
					<div class="name">{{ d.name }}</div>
					<p v-html="d.text"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';


export default {
	name: 'DestaItem',
	data() {
		return {
			dests: []
		}
	},
	methods:{
		async getDes(){
			const req = await axios.get('async/spotlight')
			this.dests = req.data
		}
	},
	mounted(){
		this.getDes()
	}
}
</script>

<style scoped>
.item {width: 32%;}

.item .content {
	display: block;
	width: 100%;
	height: auto;
	margin-top: 10px;
	background-color: #C63579;
	border-radius: 10px;
	padding: 10px;
}

.item .content .box {
	width: 100%;
	height: auto;
	background-color: rgba(0,0,0,.2);
	border-radius: 50px 10px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 6px;
	margin-top: 10px;
}
.item .content .box:first-of-type {margin-top: 0;}
.item .content .box.right {border-radius: 10px 50px;flex-direction: row-reverse;}
.item .content .box.right .text {text-align: right;margin: 0 10px 0 0;}

.item .content .box .avatar {
	width: 78px;
	height: 78px;
	background-color: #fff;
	background-position: center;
	border-radius: 46px;
}

.item .box .text {
	width: calc(100% - 100px);
	margin-left: 10px;
	height: auto;
	color: #fff;
}

.item .box .text .name {
	font-size: 15px;
	font-weight: 600;
}

.item .box .text p {font-size: 13px;margin-top: 4px;}
</style>