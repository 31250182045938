<template>
	<header>
		<div class="topheader">
			<div class="nav">
				<div class="btnMobile" @click="navMobile = !navMobile"><i class="fa-solid fa-bars"></i></div>
				<nav :class="{'mobileNav': navMobile}">
					<ul>
						<li class="limain">
							<router-link :to="{path: '/', name: ''}" @click="navMobile = !navMobile">
								<span>Inicío</span>
								<div class="icon icon1"></div>
							</router-link>
						</li>
						<li class="limain" v-for="(i, idx) in navbar" :key="idx">
							<span>{{i.category}}</span>
							<div class="icon" :style="{backgroundImage: 'url('+i.icon+')'}"></div>
							<ul class="subnav">
								<li v-for="(p, idp) in i.pages" :key="idp">
									<router-link :to="{path: `/pagina`, name: p.url, params: {id: p.id, url: p.url}}" @click="navMobile = !navMobile">
										<p>{{ p.title }}</p>
									</router-link>
								</li>
							</ul>
						</li>
						<li class="limain">
							<router-link :to="{path: '/valores', name: 'ValoresPage'}" @click="navMobile = !navMobile">
								<span>Valores</span>
								<div class="icon icon2"></div>
							</router-link>
						</li>
					</ul>
				</nav>
				<div class="boxLogin" v-if="!$store.state.user.logged">
					<button class="first" @click="modR = true">Cadastre-se</button>
					<div class="login">
						<button>Login</button>
						<div class="boxForm">
							<form @submit.prevent="login">
								<input type="text" placeholder="Usuário" v-model="user">
								<input type="password" placeholder="Senha" v-model="pass">
								<button>Entrar</button>
							</form>
						</div>
					</div>
				</div>
				<div class="logged" v-else>
					<div class="notifications">
						<div class="icon"><i class="fa-solid fa-earth-americas"></i></div>
						<div class="box-not" v-show="nots.length > 0">
							<div class="line" v-for="(n, i) in nots" :key="i">
								<div class="icon" v-if="n.fix != ''"><i class="fa-regular fa-newspaper"></i></div>
								<div class="icon" v-else><i class="fa-solid fa-paint-roller"></i></div>
								<div class="text" v-if="n.fix != ''">
									<p>Nova notícia postada, </p>
									<router-link :to="{path: '/news', name: 'NewsPage', params: {id: n.id, url: n.url}}">
										<p>{{ n.title }}</p>.
									</router-link>
								</div>
								<div class="text" v-else>
									<p>Nova arte publicada, </p>
									<router-link :to="{path: '/art', name: 'ArtPage', params: {id: n.id, url: n.url}}">
										<p>{{ n.title }}</p>.
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="name">
						<span>Olá</span>
						<div class="avatar" :style="{backgroundImage: 'url(https://imager.bubblet.com.br/?user='+$store.state.user.user+'&action=&gesture=std&direction=4&head_direction=3&headonly=true&size=b&img_format=&dance=&effect=&frame_num=30)'}"></div>
						<div class="nav-logged">
							<div class="item" @click="modA = true">Postar arte</div>
							<div class="item" @click="modC = true">Configurações</div>
							<button @click="logout()">Sair</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<LineHeader />
		<div class="header" :style="{backgroundImage: 'url('+$store.state.site.header+')'}">
			<div class="logo" :style="{backgroundImage: 'url('+$store.state.site.logo+')'}"></div>
		</div>
		<ThreeBox/>
		<Transition name="fade">
			<ModRegister v-if="modR == true" @close="modR = false" />
		</Transition>
		<Transition name="slide-fade">
			<Alerta v-if="text != ''" :text="text" />
		</Transition>
		<Transition name="fade">
			<ModArt v-if="modA" @close="modA = false" />
		</Transition>
		<Transition name="fade">
			<ModConfig v-if="modC" @close="modC = false" />
		</Transition>
	</header>
</template>

<script>
import axios from 'axios';
import LineHeader from './header/line-header.vue';
import ThreeBox from './header/three-box.vue'
import ModRegister from './modals/register.vue'
import Alerta from './util/alert.vue'

import ModArt from './modals/post-art.vue'
import ModConfig from './modals/configs.vue'

export default {
	name: 'HeaderItem',
	components: {
		LineHeader,
		ThreeBox,
		ModRegister,
		Alerta,
		ModArt,
		ModConfig
	},
	data() {
		return {
			navbar: [],
			nots: [],
			modR: false,
			user: '',
			pass: '',
			text: '',
			modA: false,
			modC: false,
			navMobile: false
		}
	},
	methods: {
		async getNav(){
			const req = await axios.get('async/navbar')
			this.navbar = req.data
		},
		async getNots(){
			const req = await axios.get('async/notifications')
			this.nots = req.data
		},
		async login(){
			const req = await axios.post('page/login', {
				user: this.user,
				pass: this.pass
			})
			if(!req.data.error) {
				this.$store.state.user = {
					user: req.data.user,
					logged: req.data.logged
				}
			}

			this.text = req.data.text
			setTimeout(() => {
				this.text = ''
			}, 5000);
		},
		async logout(){
			const req = await axios.get('page/logout')
			this.text = req.data.text
			
			this.$store.state.user = {
				user: '',
				logged: false
			}
		}
	},
	mounted(){
		this.getNav(),
		this.getNots()
	}
}
</script>

<style scoped>

header {
	position: relative;
	width: 100%;
	height: auto;
}

.topheader {
	position: relative;
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	background-color: #3FBAD3;
}

.topheader .nav {
	position: relative;
	width: var(--width);
	height: 80px;
	display: flex;
	justify-content: space-between;
}

.nav nav {
	width: 60%;
	height: 100%;
}

.nav nav > ul {
	float: left;
	width: 100%;
	height: 100%;
	list-style: none;
}

.nav nav > ul > li {
	position: relative;
	float: left;
	width: auto;
	height: 100%;
	margin-right: 4px;
	padding: 0 20px;
	cursor: pointer;
}
nav > ul > .limain:hover {
	background: rgb(55,157,178);
	background: linear-gradient(180deg, rgba(55,157,178,1) 0%, rgba(28,79,89,0.7) 100%);
}

.nav nav > ul li:hover span {opacity: .4;}
.nav nav > ul li span {
	position: relative;
	float: left;
	width: 100%;
	height: 20px;
	line-height: 20px;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	margin-top: 30px;
	z-index: 1;
}

.nav nav > ul li .icon {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-repeat: no-repeat;
	background-position: center;
	opacity: .15;
	z-index: 0;
	transition: all .5s ease;
}
.nav nav > ul li:hover .icon {opacity: 1;z-index: 9;}
.nav nav > ul li .icon.icon1 {background-image: url('../assets/img/icon1.png');}
.nav nav > ul li .icon.icon2 {background-image: url('../assets/img/icon13.png');}

.nav nav ul li > ul.subnav::before {
	content: '';
	position: absolute;
	top: -5px;
	left: 50%;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 5px 5px;
	border-color: transparent transparent #fff transparent;
	transform: translateX(-50%);
}
.nav nav ul li > ul.subnav {
	position: absolute;
	width: 200px;
	height: auto;
	background-color: #fff;
	border-radius: 10px;
	top: 68px;
	left: 50%;
	transform: translateX(-50%);
	box-shadow: 0 2px 6px rgba(0,0,0,.2);
	padding: 6px 0;
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	transition: all .3s ease-in;
	z-index: 9;
}
.nav nav ul li:hover > ul.subnav {visibility: visible;opacity: 1;top: 80px;}

nav ul li > ul.subnav li {
	width: 100%;
	height: 36px;
	transition: all .3s ease-in-out;
}

nav ul li > ul.subnav li p {
	line-height: 36px;
	font-size: 14px;
	font-weight: 500;
	color: #000;
	text-align: center;
}

nav ul li > ul.subnav li:hover {
	background-color: #F0F2F5;
	box-shadow:0 3px 6px 0px rgba(0,0,0,.2);
}

.boxLogin {
	position: relative;
	width: 30%;
	height: 80px;
	display: flex;
	justify-content: flex-end;
}

.boxLogin button.first {
	width: auto;
	height: 40px;
	background-color: #C63579;
	border-radius: 10px;
	padding: 0 16px;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	line-height: 40px;
	margin: 20px 10px 0 0;
	cursor: pointer;
	transition: all .3s ease;
}
.boxLogin button.first:hover {background-color: #fff;box-shadow: 0 0 0 2px var(--color3);color: var(--color3);}

.boxLogin .login {
	width: auto;
	height: 40px;
	margin-top: 20px;
}

.boxLogin .login button {
	width: auto;
	height: 40px;
	background-color: rgba(0,0,0,.4);
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	line-height: 40px;
	border-radius: 10px;
	padding: 0 14px;
	transition: all .3s ease;
	cursor: pointer;
}
.boxLogin .login:hover > button {
	height: 48px;
	border-radius: 10px 10px 0 0;
}

.boxLogin .login .boxForm {
	position: absolute;
	top: 60px;
	right: 0;
	width: 234px;
	height: auto;
	padding: 10px;
	background-color: #fff;
	border-radius: 10px 0 10px 10px;
	box-shadow: 0 2px 6px rgba(0,0,0,.2);
	opacity: 0;
	visibility: hidden;
	z-index: 9;
	transition: all .5s ease;
}
.boxLogin .login:hover .boxForm {opacity: 1;top: 68px;visibility: visible}

.boxLogin .boxForm input {
	width: 100%;
	height: 38px;
	border: 1px solid #707070;
	border-radius: 10px 0 10px 10px;
	margin-bottom: 6px;
	padding: 10px;
}

.boxLogin .boxForm button {
	width: 100%;
	background-color: #009ECC;
	margin-top: 0px;
	transition: all .3s ease;
}
.boxLogin .boxForm button:hover {box-shadow: 0 0 0 2px var(--color1);background-color: #fff;color: var(--color1);}

.header {
	position: relative;
	width: 100%;
	height: 350px;
	background: rgb(205,237,252);
	background: linear-gradient(180deg, rgba(205,237,252,1) 0%, rgba(161,211,235,.8) 100%);
	background-position: center;
	background-size: cover;
}

.header .logo {
	position: absolute;
	width: var(--width);
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) scale(0.5);
}

.logged {
	position: relative;
	width: 15%;
	height: 100%;
	right: 0;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 9;
}

.logged .notifications {
	position: relative;
	width: 40px;
	height: 40px;
	background-color: rgba(0,0,0,.4);
	text-align: center;
	line-height: 40px;
	color: #fff;
	font-size: 12px;
	border-radius: 20px;
	transition: all .3s ease;
	z-index: 9;
}
.logged .notifications:hover {height: 54px;border-radius: 20px 20px 0 0;}

.logged .notifications .box-not {
	position: absolute;
	top: 10px;
	right: 0;
	width: 220px;
	height: auto;
	padding: 6px 0;
	background-color: #fff;
	border-radius: 10px 0 10px 10px;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease;
	z-index: 8;
}
.logged .notifications:hover .box-not {top: 54px;opacity: 1;visibility: visible;}

.logged .notifications .box-not .line {
	float: left;
	width: 100%;
	height: auto;
	line-height: 20px;
	font-size: 13px;
	color: #000;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-bottom: 1px solid #ccc;
}
.logged .notifications .box-not .line:last-child {border: 0;}

.logged .notifications .box-not .line .icon {
	width: 30px;
	height: 30px;
	background-color: var(--color3);
	border-radius: 15px;
	color: #fff;
	line-height: 30px;
}

.logged .notifications .box-not .line .text {height: auto;}

.logged .notifications .box-not .line .text p {
	width: auto;
	height: 20px;
	line-height: 20px;
	margin-top: 4px;
}
.logged .notifications .box-not .line .text a {font-weight: 600;}

.logged .name {
	width: auto;
	height: 40px;
	background-color: rgba(0,0,0,.4);
	border-radius: 20px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0 10px;
	transition: all .4s ease;
	z-index: 9;
}
.logged .name:hover {border-radius: 10px 10px 0 0;height: 50px;}

.logged .name .avatar {
	width: 50px;
	height: 40px;
	background-repeat: no-repeat;
	background-position: center -34px;

}

.logged .name span {
	color: #fff;
	font-size: 14px;
	font-weight: 500;
}

.logged .name .nav-logged {
	position: absolute;
	top: 50px;
	right: 0;
	width: 180px;
	height: auto;
	padding: 6px 0;
	background-color: #fff;
	border-radius: 10px 0 10px 10px;
	box-shadow: 0 2px 6px rgba(0,0,0,.15);
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease-in;
	z-index: 8;
}

.logged .name:hover .nav-logged {opacity: 1;visibility: visible;top: 65px;}

.logged .name .nav-logged .item {
	width: 100%;
	height: 30px;
	line-height: 30px;
	font-size: 15px;
	font-weight: 500;
	margin-top: 4px;
	padding: 0 10px;
	cursor: pointer;
}

.logged .name .nav-logged button {
	float: right;
	margin: 10px;
	background-color: var(--color3);
	color: #fff;
	font-size: 14px;
	width: auto;
	height: 30px;
	padding: 0 6px;
	border-radius: 10px 0 10px 10px;
}

.topheader .nav .btnMobile {
	position: absolute;
	top: 16px;
	left: 16px;
	width: 45px;
	height: 45px;
	background-color: #fff;
	border-radius: 10px;
	text-align: center;
	line-height: 45px;
	font-size: 16px;
	z-index: 99;
	display: none;
}


@media only screen and (max-width: 500px) {

	.header {
		background-size: cover;
	}

	.topheader .nav .btnMobile {
		display: block;
	}
	.topheader .nav {
		justify-content: flex-end;
		flex-wrap: wrap;
		width: 100%;
		z-index: 9;
		padding-right: 20px;
	}

	.nav nav {display: none;opacity: 0; }

	.nav nav.mobileNav {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;
		display: block;
		opacity: 1;
		z-index: 98;
	}

	.nav nav.mobileNav ul {
		width: 100%;
		height: 100%;
		margin-top: 74px;
	}

	.nav nav.mobileNav ul .limain {
		float: left;
		width: 100%;
		height: 70px;
		background-color: #ccc;
		padding: 0;
	}

	.nav nav.mobileNav ul .limain .subnav {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		border-radius: 0 0 10px 10px;
		transform: translate(0);
	}

	.nav nav.mobileNav ul .limain .subnav:hover {top: 66px;}

	.boxLogin {
		width: 100%;
	}
}
</style>