<template>
	<div class="modal">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="title">Cadastra-se</div>
				<div class="subTitle">na Bubblet</div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<form @submit.prevent="setRes">
					<input type="text" name="user" placeholder="Usuário" v-model="user">
					<input type="password" name="pass" placeholder="Senha" v-model="pass">
					<input type="password" name="passr" placeholder="Repita a senha" v-model="passr">
					<input type="text" readonly :value="code">
					<button>Cadastrar</button>
				</form>
			</div>
		</div>
		<Transition name="slide-fade">
			<Alerta v-if="text != ''" :text="text"/>
		</Transition>
	</div>
</template>

<script>
import axios from 'axios'
import Alerta from '../util/alert.vue'

export default {
	name: 'ModRegister',
	components: {
		Alerta
	},
	data(){
		return {
			user: '',
			pass: '',
			passr: '',
			code: '',
			text: ''
		}
	},
	created(){
		this.getCode();
	},
	methods: {
		async getCode() {
			const req = await axios.get('async/init')
			this.code = req.data.code
		},
		async setRes(){
			const req = await axios.post('page/register', {
				user: this.user,
				pass: this.pass,
				passRepeat: this.passr
			});

			this.text = req.data.text
			setTimeout(() => {
				this.text = ''
			}, 4000);

			if(req.data.error == false){
				this.$emit('close')
				this.$store.state.user = {
					user: req.data.user,
					logged: req.data.logged
				}
			}
		}
	}
}
</script>

<style scoped>
.modal .top {height: 70px;}
</style>