<template>
	<footer>
		<div class="center">
			<div class="icon-fs"></div>
			<div class="about">
				<div class="copy">Bubblet • 2023 - {{date}}</div>
				<div class="pi">Bubblet não está afiliada com, patrocinada por, apoiada por, ou principalmente aprovada pela Rede Habblet Corporation Ltd.<br>
					A Bubblet pode utilizar as marcas registradas e outras propriedades intelectuais do Habblet, que estão permitidas sob a Política de Fã Sites Habblet.</div>
				<div class="dev">Desenvolvido por Dinhulol e <a href="https://pauloferraco.com.br/" target="_blank">Paulo Ferraço</a>.</div>
			</div>
			<div class="logo-footer" :style="{backgroundImage: 'url('+$store.state.site.logo_footer+')'}"></div>
		</div>
	</footer>
</template>

<script>


export default {
	name: 'FooterItem',
	data() {
		return {
			date: new Date().getFullYear(),
		}
	},
}
</script>

<style scoped>

footer {
	float: left;
	width: 100%;
	height: 350px;
	/* background-image: url('../assets/background-footer.png'); */
	background-color: var(--color2);
	background-position: center;
	background-size: contain;
	display: flex;
	justify-content: center;
	margin-top: 40px;
}

footer .center {
	position: relative;
	width: var(--width);
	height: 150px;
	display: flex;
	justify-content: space-between;
	margin-top: 100px;
}

footer .center .about {
	width: 65%;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
}


footer .center .about .copy {
	font-size: 12px;
}

footer .center .about .pi {
	margin-top: 10px;
	font-weight: 600;
}

footer .center .about .dev {
	font-weight: 500;
	font-size: 12px;
	margin-top: 10px;
}

footer .center .icon-fs {
	width: 78px;
	height: auto;
	background-image: url('../assets/img/icon8.png');
	background-repeat: no-repeat;
	margin-top: 26px;
	transition: all .3s linear;
}
/* footer .center .icon-fs:hover {opacity: 1;filter: grayscale(0);} */

footer .center .logo-footer {
	width: 236px;
	height: 110px;
	margin-top: 26px;
	/* background-image: url('https://i.imgur.com/Mo21TQj.png'); */
	background-repeat: no-repeat;
	background-size: contain;
}

@media only screen and (max-width: 500px) {
	footer {
		height: 380px;
		background-size: cover;
	}
	footer .center {width: 100%;flex-wrap: wrap;padding: 20px 10px;margin: 0;justify-content: space-around;}

	footer .center .about {width: 76%;}
}

</style>