import { createRouter, createWebHistory } from 'vue-router'
import IndexPage from '../views/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: IndexPage
  },
  {
    path: '/pagina/:id',
    name: 'pagina',
    component: () => import(/* webpackChunkName: "pagina" */ '../views/pagina.vue'),
  },
  {
    path: '/equipe',
    name: 'equipe',
    component: () => import(/* webpackChunkName: "equipe" */ '../views/equipe.vue'),
  },
  {
    path: '/imager',
    name: 'imager',
    component: () => import(/* webpackChunkName: "imager" */ '../views/imager.vue'),
  },
  {
    path: '/emblemas-habblet',
    name: 'badgesapi',
    component: () => import(/* webpackChunkName: "badges-page" */ '../views/badges-api.vue'),
  },
  {
    path: '/mobis-habblet',
    name: 'mobsapi',
    component: () => import(/* webpackChunkName: "mobs-page" */ '../views/mobs-api.vue'),
  },
  {
    path: '/profile-api',
    name: 'profileapi',
    component: () => import(/* webpackChunkName: "profile-page" */ '../views/profile-api.vue'),
  },
  {
    path: '/horarios',
    name: 'hourpage',
    component: () => import(/* webpackChunkName: "profile-page" */ '../views/horarios.vue'),
  },
  {
    path: '/art/:id/:url',
    name: 'ArtPage',
    props: true,
    component: () => import(/* webpackChunkName: "art" */ '../views/art.vue'),
  },
  {
    path: '/news/:id/:url',
    name: 'NewsPage',
    props: true,
    component: () => import(/* webpackChunkName: "news" */ '../views/news.vue'),
  },
  {
    path: '/recordes',
    name: 'RecordsAllPage',
    component: () => import(/* webpackChunkName: "records_all" */ '../views/records_all.vue'),
  },
  {
    path: '/recorde/:id',
    name: 'RecordPage',
    component: () => import(/* webpackChunkName: "records" */ '../views/record.vue'),
  },
  {
    path: '/valores',
    name: 'ValoresPage',
    component: () => import(/* webpackChunkName: "valores" */ '../views/valores.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
