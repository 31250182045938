<template>
	<div class="line-header">
		<div class="center">
			<div class="title">
				<div class="icon"></div>
				<span>Atualizações</span>
			</div>
			<div class="content">
				<div class="box" v-for="(c, i) in last" :key="i">
					<ToolTip :title="c.comment" v-show="c.comment.length > 18" />
					<router-link :to="{path: c.type == 'news' ? '/news' : '/art', name: c.type == 'news' ? 'NewsPage' : 'ArtPage', params: {id: c.post_id, url: c.type}}">
						<div class="icon"></div>
						<div class="mensage nobr"><span>{{c.user}}</span> comentou "{{ c.comment }}"</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import ToolTip from '../util/tooltip.vue'

export default {
	name: 'LineHeader',
	components: {
		ToolTip,
	},
	data() {
		return {
			last: [],
		}
	},
	methods:{
		async getLast(){
			const req = await axios.get('async/last-com')
			this.last = req.data
		}
	},
	mounted(){
		this.getLast()
	}
}
</script>

<style scoped>


.line-header {
	position: relative;
	width: 100%;
	height: 60px;
	background-color: var(--color2);
	display: flex;
	justify-content: center;
	z-index: 0;
}

.line-header .center {
	width: var(--width);
	height: 100%;
	display: flex;
	justify-content: space-between;
}

.line-header .title {
	float: left;
	width: 130px;
	height: 20px;
	margin-top: 20px;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
}

.line-header .title .icon {
	float: left;
	width: 20px;
	height: 20px;
	background-image: url('../../assets/img/icon2.png');
	background-repeat: no-repeat;
	margin-right: 8px;
}

.line-header .content {
	position: relative;
	width: calc(100% - 130px);
	height: 40px;
}

.line-header .content .box {
	position: relative;
	float: left;
	width: 315px;
	height: 34px;
	background-color: rgba(0,0,0,.15);
	margin: 13px 0 0 8px;
	border-radius: 20px;
	color: #fff;
	font-size: 14px;
	padding: 0 12px;
	display: flex;
	justify-content: space-between;
}

.line-header .content .box .icon {
	width: 13px;
	height: 12px;
	background-image: url('../../assets/img/icon3.png');
	background-repeat: no-repeat;
	margin: 12px 5px 0 0;
}

.line-header .content .box .mensage {
	width: calc(100% - 24px);
	height: 20px;
	line-height: 20px;
	margin-top: 7px;
	font-weight: 300;
}

.line-header .content .box .mensage span {font-weight: 500;}

@media only screen and (max-width: 500px) {
	.line-header { 
		height: auto;
		padding-bottom: 10px;
	}
	.line-header .center {
		width: 100%;
		display: block;
		height: 100%;
	}
	.line-header .title {width: 100%;float: left;margin-top: 20px;display: flex;justify-content: center;}

	.line-header .content {width: 100%;float: left;height: auto;}
	.line-header .content .box {
		width: calc(100% - 20px);
		margin-top: 4px;
	}
	.line-header .content .box .mensage {width: calc(100% - 20px);}
}
</style>