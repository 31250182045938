<template>
	<section>
		<div class="item records">
			<div class="top">
				<div class="title"><span>Recordes</span></div>
				<div class="actions">
					<router-link :to="{path: '/recordes', name: 'RecordsAllPage'}">
						<button class="btn-mais">Ver todos</button>
					</router-link>
					<button :disabled="prev == 0" @click="getRec(prev)"><i class="fa-solid fa-caret-left"></i></button>
					<button :disabled="next > total" @click="getRec(next)"><i class="fa-solid fa-caret-right"></i></button>
				</div>
			</div>
			<div class="content">
				<transition-group name="custom-classes" enter-active-class="animate__animated animate__bounceIn" leave-active-class="animate__animated animate__fadeOut">
					<div class="box" v-for="(r, i) in records" :key="i" :style="{backgroundImage: 'url('+r.image+')'}">
						<router-link :to="{path: '/recorde', name: 'RecordPage', params: {id: r.id}}">
							<div class="data">
								<div class="title nobr">{{ r.mob }}</div>
								<div class="num">{{ r.itens }}</div>
							</div>
						</router-link>
					</div>
				</transition-group>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios';



export default {
	name: 'RecordItem',
	data() {
		return {
			records: [],
			prev: 0,
			next: 0,
			total: 0,
		}
	},
	methods: {
		async getRec(p=1){
			const req = await axios.post('async/records', {
				pag: p,
				limit: 4
			})
			this.records = req.data.records
			this.prev = req.data.prev
			this.next = req.data.next
			this.total = req.data.total
		}
	},
	mounted(){
		this.getRec()
	}
}
</script>

<style scoped>
section .top .actions {width: calc(16% + 4px);}

section .top .actions .btn-mais {
	width: auto;
	background-color: var(--color1);
	padding: 0 14px;
	font-weight: 600;
}
section .top .actions .btn-mais:hover {box-shadow: 0 0 0 2px var(--color1);color: var(--color1);}

section .item .content {justify-content: space-evenly;}

section .content .box {
	width: 24%;
	height: 110px;
	background-color: #ccc;
	background-position: center;
	background-size: cover;
	border-radius: 10px;
	transition: all .3s ease;
}
section .content .box:hover {transform: scale(1.1);}

.content .box .data {
	width: 100%;
	height: 24px;
	line-height: 24px;
	font-weight: 600;
	display: flex;
	justify-content: flex-end;
}

.content .box .data .title {
	width: auto;
	max-width: 212px;
	height: 100%;
	padding: 0 14px;
	font-size: 13px;
	color: #fff;
	background-color: #C63579;
	border-radius: 0 0 0 10px;
}

.content .box .data .num {
	height: 100%;
	width: auto;
	padding: 0 12px;
	font-size: 13px;
	color: #fff;
	background-color: #E8CC19;
	border-radius: 0 10px 0 0;
}

@media only screen and (max-width: 500px) {
	section .item .top {
		height: 36px;
	}

	section .item .top .actions {
		width: 50%;
		justify-content: flex-end;
		flex-wrap: wrap;
	}

	section .content .box {
		width: calc(50% - 10px);
		margin-right: 5px;
	}

}
</style>