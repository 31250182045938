<template>
	<div class="modal">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="title">Marcar Presença</div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<form @submit.prevent="postCode">
					<span>Codigo:</span>
					<input type="text" placeholder="Codigo..." v-model="code">
					<button>Marcar</button>
				</form>
			</div>
		</div>
		<Transition name="slide-fade">
			<Alerta v-if="text != ''" :text="text" />
		</Transition>
	</div>
</template>

<script>
import axios from 'axios';
import Alerta from "../util/alert.vue";

export default {
	name: 'ModPrensence',
	components: {
		Alerta,
	},
	data() {
		return {
			code: '',
			text: ''
		}
	},
	methods: {
		async postCode(){
			if(this.code !== ''){
				const req = await axios.post('page/presenca', {
					code: this.code
				})

				this.text = req.data.text
				setTimeout(() => {
					this.text = ''
				}, 3000);
				if(!req.data.error){
					this.$emit('close')
				}
			}
			this.text = 'Digite um codigo!';
			setTimeout(() => {
				this.text = ''
			}, 3000);
		}
	}
}
</script>

<style scoped>

</style>