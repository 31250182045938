<template>
	<div class="slide">
		<transition-group name="custom-classes" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
			<div class="slides" v-for="(s, i) in slides" :key="i" :style="{backgroundImage: 'url('+s.image+')'}" v-show="currentIndex == i">
				<div class="data" v-if="s.guia">
					<a :href="s.url" target="_blank" rel="noopener noreferrer">
						<div class="title nobr">{{ s.title }}</div>
					</a>
					<div class="description nobr">{{ s.description }}</div>
				</div>
				<div class="data" v-else>
					<a :href="s.url" rel="noopener noreferrer">
						<div class="title nobr">{{ s.title }}</div>
					</a>
					<div class="description nobr">{{ s.description }}</div>
				</div>
			</div>
		</transition-group>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'SlideItem',
	data() {
		return {
			slides: [],
			time: null,
			currentIndex: 0
		}
	},
	methods: {
		async getSlides(){
			const req = await axios.get('async/slide')
			this.slides = req.data
		},
		startSlide(){
			this.getSlides();
			setInterval(() => {
				this.next();
			}, 10000);
		},
		next() {
			if(this.currentIndex == (this.slides.length - 1)){
				this.currentIndex = 0
            }else{
                this.currentIndex += 1;
            }
		},
		prev() {
			this.currentIndex -= 1;
		}
	},
	mounted(){
		this.startSlide();
	}
}
</script>

<style scoped>

.slide {
	position: relative;
	width: 37%;
}

.slide .slides {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #ccc;
	background-position: center;
	background-size: cover;
	border-radius: 10px;
	box-shadow: 0 2px 6px rgba(0,0,0,.15);
}

.slide .slides .data {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 64px;
	background-color: rgba(0,0,0,.4);
	border-radius: 0 0 10px 10px;
	color: #fff;
	padding: 0 20px;
}

.slide .slides .data .title {
	margin-top: 10px;
	font-size: 16px;
	font-weight: 600;
}

.slide .slides .data .description {
	font-size: 14px;
	margin-top: 6px;
}

</style>