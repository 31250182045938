<template>
	<section>
		<div class="item art">
			<div class="top">
				<div class="title"><span>Promotoria</span> Oficial</div>
				<div class="actions">
					<div class="box-cat">
						<div class="option">
							<div class="nobr">{{textCat}}</div>
							<i class="fa-solid fa-sort-down"></i>
						</div>
						<div class="options">
							<div  @click="textCat = 'Todas', getArts('', 0)">Todas</div>
							<div v-for="(c, i) in cats" :key="i" @click="textCat = c.category, getArts('', c.id)">{{ c.category }}</div>
						</div>
					</div>
					<button :disabled="prev == 0" @click="getArts('', cat, prev)"><i class="fa-solid fa-caret-left"></i></button>
					<button :disabled="next > total" @click="getArts('', cat, next)"><i class="fa-solid fa-caret-right"></i></button>
				</div>
			</div>
			<div class="content">
				<transition-group name="custom-classes" enter-active-class="animate__animated animate__bounceIn" leave-active-class="animate__animated animate__fadeOut">
					<div class="box" v-for="(a, i) in arts" :key="i" :style="{backgroundImage: 'url('+a.image+')'}">
						<div class="data">
							<div class="category">{{a.category}}</div>
							<router-link :to="{path: '/art', name: 'ArtPage', params: {id: a.id, url: a.url}}">
								<div class="title nobr">{{ a.title }}</div>
							</router-link>
						</div>
					</div>
				</transition-group>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios';

export default {
	name: 'ArtItem',
	data() {
		return {
			textCat: 'Todas',
			cats: [],
			arts: [],
			cat: 0,
			search: '',
			prev: 0,
			next: 0,
			total: 0
		}
	},
	methods: {
		async getCat(){
			const req = await axios.get('async/category-arts')
			this.cats = req.data
		},
		async getArts(s='', c=0, p=1){
			const req = await axios.post('async/arts', {
				pag: p,
				cat: c,
				s: s
			})
			this.arts = req.data.arts
			this.prev = req.data.prev
			this.next = req.data.next
			this.total = req.data.total
		}
	},
	mounted(){
		this.getCat();
		this.getArts();
	}
}
</script>

<style scoped>

section {
	position: relative;
	width: 100%;
	height: auto;
	background-color: var(--color2);
	box-shadow: 0 2px 6px rgba(0,0,0,.2);
	display: flex;
	justify-content: center;
	padding: 20px 0;
	margin-top: 30px;
	border-bottom: 4px solid var(--color1);
}

section .item .top .title {color: #fff;}
section .item .top .actions {width: 19%;}

section .top .actions button {	background-color: rgba(0,0,0,.4);}
section .top .actions button:disabled {background-color: rgba(0,0,0,.15);}

.art .content {
	justify-content: space-around;
}

.art .box {
	position: relative;
	width: 24%;
	height: 190px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 10px;
	border: 1px solid #000;
	overflow: hidden;
}

.art .box .data {
	position: absolute;
	bottom: -40%;
	left: 0;
	width: 100%;
	height: 40%;
	background: rgb(0,39,178);
	background: linear-gradient(130deg, rgba(0,39,178,0) 0%, rgba(255,255,255,1) 100%);
	border-radius: 10px 0 10px 10px;
	padding: 16px 10px;
	transition: all .4s ease;
}
.art .box:hover .data {
	bottom: 0;
}

.art .box .data .category {
	float: left;
	width: auto;
	height: 26px;
	line-height: 26px;
	background-color: var(--color1);
	border-radius: 10px;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	padding: 0 10px;
}

.art .box .data .title {
	float: left;
	width: 100%;
	height: 20px;
	font-size: 15px;
	font-weight: 600;
	margin-top: 6px;
}

@media only screen and (max-width: 500px) {
	section .item .top {
		height: 36px;
	}

	section .item .top .actions {
		width: 56%;
		justify-content: flex-end;
		flex-wrap: wrap;
	}

	section .item .content {
		flex-wrap: wrap;
	}

	section .item .content .box {
		width: calc(50% - 10px);
		margin: 5px;
	}

}
</style>