<template>
	<section>
		<div class="item last-add" v-show="mobs.length > 0">
			<div class="top">
				<div></div>
				<div class="title"><span>Mobílias</span> Adicionados</div>
				<div class="actions">
					<div class="search">
						<div class="icon"><i class="fa-solid fa-magnifying-glass"></i></div>
						<input type="text" placeholder="Pesquise..." v-model="search" @keydown.enter="getBad(search)">
					</div>
					<button :disabled="prev == 0" @click="getBad('', prev)"><i class="fa-solid fa-caret-left"></i></button>
					<button @click="getBad('', next)"><i class="fa-solid fa-caret-right"></i></button>
				</div>
			</div>
			<div class="content 17">
				<transition-group name="custom-classes" enter-active-class="animate__animated animate__bounceIn" leave-active-class="animate__animated animate__fadeOut">
					<div class="box" v-for="(b, i) in mobs" :key="i" :style="{backgroundImage: 'url(https://images.habblet.city/library/hof_furni/icons/'+b.classname+'_icon.png)'}">
						<ToolTip :title="b.name" />
					</div>
				</transition-group>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import ToolTip from '../util/tooltip.vue'

export default {
	name: 'LastMobs',
	components: {
		ToolTip,
	},
	data() {
		return {
			mobs: [],
			prev: 0,
			next: 0,
			search: ''
		}
	},
	methods: {
		async getBad(s, p=1){
			let limit = 17;
			if(navigator.userAgentData.mobile){
				limit = 18
			}

			const req = await axios.get(this.$store.state.urlAPI+'/habblet_mobs/'+limit+'/'+p+'?search='+s)
			this.mobs = req.data.mobs ?? []
			this.prev = req.data.prev
			this.next = req.data.next
		}
	},
	mounted(){
		this.getBad('')
	}
}
</script>

<style scoped>

section .item .top .actions {width: 25%}

.last-add .content {
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
	display: flex;
	justify-content: space-between;
	padding: 10px;
}

.last-add .content .box  {
	position: relative;
	width: 56px;
	height: 56px;
	background-color: #E6E6E6;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 10px;
}


@media only screen and (max-width: 500px) {
	section .item .top {
		height: 76px;
	}

	section .item .top .actions {
		width: 50%;
		justify-content: flex-end;
		flex-wrap: wrap;
	}

	.item .content {
		height: 208px;
		flex-wrap: wrap;
		align-items: center;
	}
}
</style>