<template>
	<section>
		<div class="news item">
			<div class="top">
				<div></div>
				<div class="title"><span>Últimas</span> Notícias</div>
				<div class="actions">
					<div class="box-cat">
						<div class="option">
							<div class="nobr">{{textCat}}</div>
							<i class="fa-solid fa-sort-down"></i>
						</div>
						<div class="options">
							<div  @click="textCat = 'Todas', getNews(0), cat = 0">Todas</div>
							<div v-for="(c, i) in cats" :key="i" @click="textCat = c.category, getNews(c.id), cat = c.id">{{ c.category }}</div>
						</div>
					</div>
					<div class="search">
						<div class="icon"><i class="fa-solid fa-magnifying-glass"></i></div>
						<input type="text" placeholder="Pesquise..." v-model="search" @change="getNews(0, 1, this.search)">
					</div>
					<button :disabled="prev == 0" @click="getNews(cat, prev)"><i class="fa-solid fa-caret-left"></i></button>
					<button :disabled="next > total" @click="getNews(cat, next)"><i class="fa-solid fa-caret-right"></i></button>
				</div>
			</div>
			<div class="content">
				<transition-group name="custom-classes" enter-active-class="animate__animated animate__bounceIn" leave-active-class="animate__animated animate__fadeOut">
					<div class="box" v-for="(n, i) in news" :key="i" :style="{backgroundImage: 'url('+n.image+')'}">
						<div class="data">
							<router-link :to="{path: '/news', name: 'NewsPage', params: {id: n.id, url: n.url}}">
								<div class="title nobr">{{ n.title }}</div>
							</router-link>
							<div class="category">{{n.category}}</div>
							<div class="time">
								<div class="icon"></div>
								<span>hà {{ n.time }}</span>
							</div>
							<div class="fix" v-if="n.fix"></div>
						</div>
					</div>
				</transition-group>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios';

export default {
    name: "NewsItem",
    data() {
        return {
            textCat: "Todas",
            cats: [],
            news: [],
            cat: 0,
            prev: 0,
            next: 0,
            total: 0,
            search: ""
        };
    },
    methods: {
        async getCat() {
            const req = await axios.get("async/category-news");
            this.cats = req.data;
        },
        async getNews(c = 0, pag = 1, s = "") {
            const req = await axios.post("async/news", {
                cat: c,
                pag: pag,
                s: s
            });
            this.news = req.data.news;
            this.prev = req.data.prev;
            this.next = req.data.next;
            this.total = req.data.total;
        }
    },
    mounted() {
        this.getCat();
        this.getNews();
    },
}
</script>

<style scoped>

.news .content {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.news .box {
	position: relative;
	width: 32%;
	height: 160px;
	background-position: center;
	background-size: cover;
	border-radius: 10px;
	margin: 6px 0;
	transition: all .3s ease;
}
.news .box:hover {box-shadow: 0 0 10px var(--color1); transform: scale(1.1);}

.news .box .data {
	float: left;
	width: 70%;
	height: 100%;
	background-color: rgba(0, 0, 0, .4);
	border-radius: 10px;
	transition: all .3s ease;
}
.news .box:hover .data {width: 100%;}

.news .box .title {
	width: 100%;
	height: 20px;
	margin-top: 10px;
	font-size: 15px;
	font-weight: 500;
	color: #fff;
	padding-left: 10px;
}

.news .box .category {
	width: 100%;
	height: 20px;
	font-size: 13px;
	padding-left: 10px;
	color: #fff;
}

.news .box .data .time {
	width: 100%;
	height: 20px;
	font-size: 12px;
	margin: 86px 0 0 10px;
	color: #fff;
}

.news .box .data .time .icon {
	float: left;
	width: 15px;
	height: 13px;
	background-image: url('../../assets/img/icon9.png');
	background-repeat: no-repeat;
	margin-right: 6px;
}

.news .box .data .fix {
	position: absolute;
	bottom: 5px;
	right: 0;
	width: 30px;
	height: 30px;
	background-color: #E8CC19;
	background-image: url('../../assets/img/icon10.png');
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 10px 0 0 10px;
}

@media only screen and (max-width: 500px) {
	section .item .top {
		height: 76px;
	}

	section .item .top .actions {
		width: 80%;
		justify-content: flex-end;
		flex-wrap: wrap;
	}


	.news .box {
		width: calc(100% - 20px);
		margin-left: 10px;
	}
}
</style>