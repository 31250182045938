<template>
	<div class="player header-item">
		<div class="top">
			<div class="title"><span>Rádio</span> Bubblet</div>
			<div class="ouvintes">
				<div class="icon"></div>
				<span>{{data.ouvintes}}</span>
			</div>
		</div>
		<div class="content">
			<div class="box-avatar">
				<div class="avatar" :style="{backgroundImage: 'url(https://imager.bubblet.com.br/?user='+data.locutor+'&action=&gesture=std&direction=2&head_direction=2&headonly=false&size=b&img_format=&dance=&effect=&frame_num=30)'}"></div>
				<div class="mic-icon"></div>
				<div class="base"></div>
			</div>
			<div class="data-player">
				<div class="noar">AGORA</div>
				<div class="locutor">{{data.locutor}}</div>
				<div class="program"><span>com</span> {{ data.programa }}</div>
				<input type="range" name="volume" id="vol" max="100" min="0"  v-model="volume" @click="rangeVol($event)">
				<div class="mark-pres" v-if="$store.state.user.logged" @click="modP = true">
					<div class="icon"></div>
					<span>Marcar presença</span>
				</div>
				<div class="mark-pres" v-if="$store.state.user.logged" @click="like">
					<div class="icon like"></div>
					<span>Dar like</span>
				</div>
			</div>
		</div>
		<Transition name="fade">
			<ModPresence v-if="modP" @close="modP = false" @text="alerttext(e)" />
		</Transition>
	</div>
</template>

<script>
import axios from 'axios';
import ModPresence from '../modals/mod-prensence.vue'

export default {
	name: 'PlayerItem',
	components: {
		ModPresence
	},
	data() {
		return {
			data: [],
			volume: 60,
			playing: true,
			textA: '',
			modP: false,
			modL: false,
			audio: ''
		}
	},
	methods: {
		initPlay(){
			axios.get('page/status')
			.then((a) => {
				this.data = a.data
				this.setAudio()
			})
		},
		getPlay(){
			axios.get('page/status')
			.then((a) => {
				this.data = a.data
			})
		},
		rangeVol(){
			const ele = document.getElementById("vol")

			let value = ele.value;
			ele.style.background = 'linear-gradient(to right, var(--color1) '+value+'%, var(--color1) ' + value + '%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%)';
			ele.setAttribute('value', this.volume)
			
			this.Volume(this.volume/100)
		},
		Play(p){
			const icon = document.querySelector('.play .fa-solid');
			this.playing = !this.playing;
			if(p == true){
				icon.classList.remove("fa-play");
				icon.classList.add("fa-pause");
				this.Volume(0.6)
			}else{
				icon.classList.remove("fa-pause");
				icon.classList.add("fa-play");
				this.Volume(0)
			}
		},
		Volume(v){
			this.audio.volume = v;
		},
		setAudio(){
			this.audio = new Audio(`https://${this.data.ip}/${this.data.port}/stream`);
			// document.getElementById("audio").play()
			this.audio.addEventListener("canplaythrough", () => {
				this.audio.play().catch(() => {
					window.addEventListener('click', () => {
						this.audio.play()
					}, { once: true })
				})
			});
		},
		alerttext(t){
			this.textA = t
			setTimeout(() => {
				this.textA = ''
			}, 3000);
		},
		async like(){
			const req = await axios.get('async/Loclike')
			this.alerttext(req.data.text)
		}
	},
	mounted(){
		this.initPlay();
		setInterval(() => {
			this.getPlay()
		}, 10000);
	}
}
</script>

<style scoped>
 
.header-item {
	width: 30%;
	height: 100%;
}

.header-item .top {
	float: left;
	width: 100%;
	height: 46px;
	background-color: #3EBAD3;
	border-radius: 10px 10px 0 0;
	padding: 0 10px 0 16px;
	display: flex;
	justify-content: space-between;
}

.header-item .top .title {
	width: 50%;
	height: 20px;
	font-size: 15px;
	color: #fff;
	font-weight: 500;
	margin-top: 13px;
}

.header-item .top .title span {
	/* font-weight: 500; */
}

.header-item .top .ouvintes {
	width: auto;
	height: 30px;
	margin-top: 10px;
	background-color: rgba(0, 0, 0, .3);
	color: #fff;
	border-radius: 10px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	line-height: 30px;
	padding: 0 8px;
}


.header-item .content {
	float: left;
	width: 100%;
	height: calc(100% - 46px);
	background-color: #fff;
	box-shadow: 0 2px 6px rgba(0,0,0,.15);
	border-radius: 0 0 10px 10px;
	padding: 10px;
}

.header-item .top .ouvintes .icon {
	width: 14px;
	height: 14px;
	background-image: url('../../assets/img/icon4.png');
	background-repeat: no-repeat;
	margin: 8px 4px 0 0;
	float: left;
}

.three-box .player .content {
	display: flex;
	justify-content: space-between;
	padding: 0;
}

.three-box .player .content .box-avatar {
	position: relative;
	width: 35%;
	height: auto;
	background-image: url('../../assets/img/back-player.png');
	background-repeat: no-repeat;
	background-position: -150px -36px;
}

.player .box-avatar .avatar {
	position: relative;
	width: 56px;
	height: 110px;
	background-position: center;
	background-repeat: no-repeat;
	margin: 30px 0 0 14px;
	z-index: 1;
}

.player .content .box-avatar .base {
	position: relative;
	width: 160px;
	height: 100px;
	background-image: url('../../assets/img/icon6.png');
	margin: -32px 0 0 -24px;
	z-index: 0;
}

.player .content .box-avatar .mic-icon {
	position: absolute;
	top: 80px;
	left: 44px;
	width: 37px;
	height: 69px;
	background-image: url('../../assets/img/icon7.png');
	z-index: 3;
}

.player .content .data-player {
	width: 62%;
	height: 100%;
	padding: 14px;
}

.player .content .data-player .noar {
	float: left;
	width: auto;
	height: 24px;
	background-color: #C63579;
	border-radius: 10px;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	line-height: 24px;
	user-select: none;
	padding: 0 8px;
}

.player .content .data-player .locutor {
	float: left;
	width: 100%;
	height: 20px;
	font-size: 16px;
	font-weight: 600;
	margin-top: 10px;
}

.player .content .data-player .program {
	float: left;
	width: 100%;
	height: 38px;
	overflow: hidden;
	font-size: 13px;
	font-weight: 500;
	margin-top: 4px;
}

.player .content .data-player .program span {font-size: 11px;font-weight: 400;}

.player .content .data-player .progress {
	float: left;
	width: 100%;
	height: 16px;
	background-color: #306B7D;
	border-radius: 10px;
	margin-top: 6px;
}

.player .content .data-player .progress .bar {
	max-width: calc(100% - 4px);
	height: 10px;
	margin: 2px 0 0 2px;
	background-color: #39B7D1;
	border-radius: 10px;
	cursor: pointer;
}

.player .content .data-player .mark-pres {
	float: left;
	width: 100%;
	height: 36px;
	background-color: #EFEFEF;
	border-radius: 10px;
	font-size: 14px;
	font-weight: 500;
	margin-top: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all .3s ease;
}

.player .content .data-player .mark-pres .icon {
	width: 17px;
	height: 17px;
	background-image: url('../../assets/img/icon5.png');
	margin: -2px 6px 0 0;
}

.player .content .data-player .mark-pres .icon.like {
	background-image: url('../../assets/img/icon12.png');
}

.player .content .data-player .mark-pres:hover {background-color: var(--color3);color: #fff;}

.player  input {
    float: left;
    width: 180px;
    height: 12px;
	-webkit-appearance: none;
	border-radius: 10px;
	margin-top: 6px;
	background-color: rgba(36,78,102,.07);
	background: linear-gradient(to right, var(--color1) 60%, var(--color1) 60%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
}

.player  input::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #fff;
	cursor: pointer;
	box-shadow: 0 0 6px rgba(0,0,0,.2);
	margin-top: 0px;
}
</style>