<template>
	<section>
		<div class="center">
			<RankLoc/>
			<RankCom/>
			<DestaqItem/>
		</div>
	</section>
</template>

<script>
import RankLoc from './sub-com/rank-loc.vue'
import RankCom from './sub-com/rank-com.vue'
import DestaqItem from './sub-com/destaq.vue'


export default {
	name: 'RanksItem',
	components: {
		RankCom,
		RankLoc,
		DestaqItem
	},
}
</script>

<style scoped>

@media only screen and (max-width: 500px) {
	section .center .item {
		width: 100%;
		margin-top: 20px;
	}
}
</style>