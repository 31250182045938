<template>
	<template v-if="$store.state.site.maintenance">
		<audio autoplay hidden controls :src="'http://'+$store.state.site.ip+':'+$store.state.site.port+'/stream'"></audio>
	</template>
	<template v-else>
		<HeaderItem />
		<router-view :key="this.$route.fullPath"/>
		<FooterItem />
	</template>
</template>

<script>
import HeaderItem from './components/header.vue';
import FooterItem from './components/footer.vue';

export default {
	components: {
		HeaderItem,
		FooterItem
	},
	methods: {
		init(){
			this.$store.dispatch('initSys')
			this.$store.dispatch('initUser')
		}
	},
	mounted(){
		this.init();
	}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
	font-family: 'Montserrat', sans-serif;
	background-color: var(--body);
	background-image: url('./assets/img/background.png');
}
body::-webkit-scrollbar{width: 6px;}
body::-webkit-scrollbar-button { display: none; }
body::-webkit-scrollbar-track-piece { background-color: #fff; }
body::-webkit-scrollbar-thumb { background-color: rgba(0,0,0,0.3); width: 6px; border-radius: 4px;}


* {padding: 0;margin: 0;box-sizing: border-box;}

button,textarea,li,ul,select,input {border:0;list-style: none;background-color: transparent;outline: none;color: inherit;text-decoration: none;font-family: 'Montserrat', sans-serif;}
a {display: contents;text-decoration: none;color: inherit;}
input[type=radio] {display: none;}
input[type=file] {padding: 0 10px !important;font-size: 14px;}

.nobr {white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}

#app {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 0;
}

:root {
  --color1: #3BB9D2;
  --color2: #306B7D;
  --color3: #C63579;
  --warning: rgb(245, 233, 71);
  --width: 1100px;
  --body: #F2F3F6;
}


.home {
	position: relative;
	width: 100%;
	height: auto;
	margin: 50px 0;
}

section {
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	margin-top: 30px;
}

section .center {
	width: var(--width);
	display: flex;
	justify-content: space-between;
}

section .item {
	position: relative;
	width: var(--width);
	height: auto;
}
section .item .top {
	position: relative;
	width: 100%;
	height: 36px;
}

section .item .top .title {
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	width: auto;
	height: 100%;
	font-size: 16px;
	color: #050505;
	text-shadow: 0 3px 6px rgba(0,0,0,.25);
	line-height: 36px;
	user-select: none;
	text-align: center;
}

section .item .top .title span {font-weight: 600;}

section .item .top .actions {
	float: right;
	width: 36%;
	height: 36px;
	position: relative;
	display: flex;
	justify-content: space-between;
}

section .item .top .actions .box-cat {
	position: relative;
	width: 120px;
	height: 36px;
	border-radius: 10px;
	background-color: var(--color1);
	padding: 0 16px;
}

section .item .top .actions .box-cat .option {
	width: 100%;
	height: 36px;
	font-size: 14px;
	font-weight: 500;
	color: #fff;
	line-height: 36px;
	text-align: center;
	user-select: none;
}

section .item .top .actions .box-cat .option > div {width: 100%;}

section .item .top .actions .box-cat .option i {
	position: absolute;
	right: 8px;
	top: 8px;
}

section .item .top .actions .box-cat .options::before {
	content: '';
	position: relative;
	display: block;
	left: 50%;
	top: -10px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 5px 5px;
	border-color: transparent transparent #fff transparent;
}

section .item .top .actions .box-cat .options {
	position: absolute;
	top: 36px;
	left: 50%;
	transform: translateX(-50%);
	width: 150px;
	height: auto;
	max-height: 180px;
	overflow: auto;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 6px rgba(0,0,0,.15);
	padding: 6px 0;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease;
	z-index: 99;
}
section .item .top .actions .box-cat .options::-webkit-scrollbar{width: 4px;}
section .item .top .actions .box-cat .options::-webkit-scrollbar-button { display: none; }
section .item .top .actions .box-cat .options::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0.0); }
section .item .top .actions .box-cat .options::-webkit-scrollbar-thumb { background-color: rgba(0,0,0,0.3); width: 4px; border-radius: 4px;}

section .item .top .actions .box-cat:hover .options {opacity: 1;visibility: visible;}

section .item .top .actions .box-cat .options div {
	width: 100%;
	height: 30px;
	text-align: center;
	line-height: 30px;
	user-select: none;
	border-bottom: 1px solid rgba(0,0,0,.1);
	cursor: pointer;
	transition: all .3s ease;
}
section .item .top .actions .box-cat .options div:last-of-type {border: 0}
section .item .top .actions .box-cat .options div:hover {background-color: rgba(0,0,0,.2);}

section .item .top .actions .search {
	position: relative;
	width: 180px;
	margin-left: 8px;
}

section .item .top .actions .search input {
	position: relative;
	width: 100%;
	height: 36px;
	padding: 0 0 0 44px;
	font-size: 14px;
	background-color: #D6D6D6;
	border-radius: 10px;
	z-index: 0;
}

section .item .top .actions .search .icon {
	position: absolute;
	width: 36px;
	height: 36px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	line-height: 36px;
	text-align: center;
	background-color: var(--color3);
	border-radius: 10px 0;
	z-index: 1;
}

section .item .top .actions button {
	width: 36px;
	height: 36px;
	background-color: var(--color3);
	color: #fff;
	font-size: 13px;
	border-radius: 10px;
	transition: all .3s ease;
	cursor: pointer;
}
section .item .top .actions button:hover { background-color: #fff;box-shadow: 0 0 0 2px var(--color3) ; color: var(--color3);}

section .item .top .actions button:disabled {opacity: .3;}

section .item .content {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.page {
	width: var(--width);
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.page .page-in {
	width: calc(70% - 0px);
	position: relative;
	height: auto;
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.page aside {
	float: right;
	width: calc(30% - 20px);
}

.page-in .top,
.page-in .content {
	float: left;
	position: relative;
	width: 100%;
	height: auto;
}

.page-in .top {
	font-size: 18px;
	font-weight: 500;
	height: 40px;
}

.page-in .content {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 6px rgba(0,0,0,.15);
	padding: 10px;
	font-size: 14px;
	margin-top: 10px;
}

/* MODALS */


.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.4);
	z-index: 1;
}

.modal .empty {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;
}

.modal .box {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 400px;
	height: auto;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 1px 6px rgba(0,0,0,.15);
	transform: translate(-50%,-50%);
	z-index: 1;
}

.modal .top {
	position: relative;
	float: left;
	width: 100%;
	height: 50px;
	border-bottom: 2px solid rgba(0,0,0,.1);
	padding: 10px;
	user-select: none;
	line-height: 20px;
}

.modal .top .title {
	float: left;
	width: 70%;
	height: 24px;
	font-size: 20px;
	font-weight: 800;
	color: #050505;
	margin-top: 4px;
}

.modal .top .subTitle {
	float: left;
	width: 70%;
	height: 20px;
	font-size: 14px;
}

.modal .top .close {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 20px;
	height: 20px;
	color: #A7A7A7;
	font-size: 14px;
	text-align: center;
	line-height: 20px;
	user-select: none;
}

.modal .content {
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	padding: 10px;
	box-shadow: none;
}

.modal .content input,
.modal .content textarea,
.modal .content select {
	float: left;
	width: 100%;
	max-width: 100%;
	min-width: 100%;
	height: 40px;
	padding: 10px;
	border-radius: 12px;
	background-color: rgba(0,0,0,.1);
	margin-top: 6px;
	font-size: 14px;color: #444;
}
.modal .content input::placeholder {font-size: 14px;color: #444;}

.modal .content button {
	float: left;
	width: auto;
	height: 36px;
	background-color: var(--color3);
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	border-radius: 12px;
	padding: 0 30px;
	margin: 14px 0 0 50%;
	transform: translateX(-50%);
	transition: all .3s ease;
}
.modal .content button:hover {background-color: #fff;box-shadow: 0 0 0 2px var(--color3);color: var(--color3);}

/* ANIMAÇOES */

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media only screen and (max-width: 500px) {
	.modal {
		width: 100vw;
		height: 100vh;
		z-index: 999;
	}
	.modal .box {
		left: 10px;
		width: calc(100% - 20px);
		transform: translate(0, -50%);
	}

	section .item {
		width: 100%;
		padding: 0 10px;
	}

	section .item .top .title {
		left: 0;
		transform: translate(0);
	}

	section .item .top .actions {
		width: 70%;
		height: 100%;
	}

	section .item .top .actions > div,
	section .item .top .actions button {margin-right: 6px;}

	section .center {
		width: 100%;
		display: block;
	}

	.page {width: 100%;}
	.page .page-in {width: 100%;padding: 0 20px;}
}
</style>
