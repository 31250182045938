<template>
	<section>
		<div class="valores item">
			<div class="top">
				<div></div>
				<div class="title"><span>Valores</span></div>
				<div class="actions">
					<button :disabled="prev == 0"><i class="fa-solid fa-caret-left"></i></button>
					<button :disabled="next > total"><i class="fa-solid fa-caret-right"></i></button>
				</div>
			</div>
			<div class="content">
				<transition-group name="custom-classes" enter-active-class="animate__animated animate__bounceIn" leave-active-class="animate__animated animate__fadeOut">
					<div class="box" v-for="(m, i) in mobs" :key="i">
						<div class="infos">
							<div class="info title nobr">{{ m.mob }}</div>
							<div class="info price">R$ {{ m.price }}</div>
							<div class="info stats" v-show="m.stats == 1"><i class="fa-solid fa-up-long"></i></div>
							<div class="info stats" v-show="m.stats == 2"><i class="fa-solid fa-down-long"></i></div>
							<div class="info stats" v-show="m.stats == 3"><i class="fa-solid fa-minus"></i></div>
						</div>
						<div class="img" :style="{backgroundImage: 'url('+m.icon+')'}"></div>
					</div>
				</transition-group>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios';

export default {
	name: 'EventsItem',
	data() {
		return {
			mobs: [],
			prev: 0,
			next: 0,
			total: 0
		}
	},
	methods: {
		async getMobs(p=1, s=''){
			const req = await axios.post('async/valores', {
				pag: p,
				limit: 5,
				search: s,
				cat: 0
			})
			this.mobs = req.data.valores
			this.prev = req.data.prev
			this.next = req.data.next
			this.total = req.data.total
		}
	},
	mounted(){
		this.getMobs()
	}
}
</script>

<style scoped>

.home section {
	position: relative;
	width: 100%;
	height: auto;
	background-color: rgba(40, 116, 131, 0.85);
	/* background-image: url(../../assets/img/background2.png); */
	box-shadow: 0 2px 5px rgba(0,0,0,.15);
	display: flex;
	justify-content: center;
	padding: 20px 0;
	margin-top: 0px;
}

.top .title {color: #fff !important;}

section .item .top .actions {width: calc(7% + 4px);}

section .top .actions button {
	background-color: rgba(0,0,0,.4);
}
section .top .actions button:disabled {background-color: rgba(0,0,0,.15);}

.valores .content {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

/* .valores .box {
	position: relative;
	width: 32%;
	height: 160px;
	background-position: center;
	background-size: cover;
	border-radius: 10px;
} */


.valores .content .box {
	width: calc(100% / 5 - 10px);
	height: 140px;
	background-color: #D9E1EE;
	border-radius: 10px;
	margin: 5px;
}

.valores .content .box .infos {
	float: right;
	width: auto;
	height: 26px;
	background-color: var(--color1);
	padding-left: 10px;
	border-radius: 0 10px;
	display: flex;
}

.valores .content .box .infos {
	line-height: 26px;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
}

.valores .content .box .infos .info.title{
	margin-right: 10px;
	max-width: 95px;
}

.valores .content .box .infos .price {
	padding: 0 8px;
	background-color: var(--color3);
	border-radius: 0 0 0 10px;
}
.valores .content .box .infos .stats {
	background-color: var(--color2);
	border-radius: 0 10px 0 0;
	padding: 0 8px;
}

.valores .content .box .img {
	width: 50%;
	height: 50%;
	margin: 36px 0 0 50px;
	background-position: center;
	background-repeat: no-repeat;
	transform: scale(2);
	image-rendering: pixelated;
}


@media only screen and (max-width: 500px) {
	section .item .top {
		height: 36px;
	}

	section .item .top .actions {
		width: 50%;
		justify-content: flex-end;
		flex-wrap: wrap;
	}

	.events .box {
		width: calc(100% - 20px);
		margin-left: 10px;
	}
}
</style>