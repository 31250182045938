<template>
	<div class="three-box">
		<div class="before-header"><div class="corner"></div></div>
		<div class="center">
			<div class="free-items header-item">
				<div class="top">
					<div class="title"><span>Item</span> disponíveis</div>
					<div class="actions">
						<button :disabled='prev == 0' @click="getBadges(prev)"><i class="fa-solid fa-caret-up"></i></button>
						<button :disabled="next >= total" @click="getBadges(next)"><i class="fa-solid fa-caret-down"></i></button>
					</div>
				</div>
				<div class="content">
					<TransitionGroup name="list">
						<div class="box" v-for="(b, i) in badges" :key="i">
							<router-link :to="{path: '/news', name: 'NewsPage', params: {id: b.id_news, url: 'news'}}">
								<div :style="{backgroundImage: 'url(https://images.habblet.city/c_images/album1584/'+b.code+'.gif)'}">
									<ToolTip :title="b.item" />
								</div>
							</router-link>
						</div>
					</TransitionGroup>
				</div>
			</div>
			<SlideItem/>
			<PlayerItem />
		</div>
		<div class="after-header"><div class="corner"></div></div>
	</div>
</template>

<script>
import axios from 'axios';
import SlideItem from './slide.vue';
import PlayerItem from './player.vue';
import ToolTip from '../util/tooltip.vue';

export default {
	name: 'ThreeBox',
	components: {
		SlideItem,
		ToolTip,
		PlayerItem
	},
	data() {
		return {
			badges: [],
			prev: 0,
			next: 0,
			total: 0,
		}
	},
	methods: {
		async getBadges(p=1){
			let limit = 15;
			
			const req = await axios.post('async/badges-free', {
				limit: limit,
				pag: p
			})
			this.badges = req.data.badges
			this.prev = req.data.prev;
			this.next = req.data.next;
			this.total = req.data.total
		}
	},
	mounted(){
		this.getBadges()
	}
}
</script>

<style scoped>

.three-box {
	position: relative;
	width: 100%;
	height: 310px;
	display: flex;
	justify-content: center;
	margin: 0 auto;
	color: #050505;
}

.three-box .before-header {
	position: absolute;
	left: 0;
	top: 0;
	width: calc((100% - var(--width)) / 2 - 20px);
	height: 220px;
	background-color: rgba(0,0,0,.04);
}
.three-box .before-header .corner {
	position: absolute;
	right: 0;
	bottom: -30px;
	width: 30px;
	height: 30px;
	background-color: rgba(0,0,0,.05);
}
.three-box .before-header .corner::after {
	display: block;
	content: '';
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--body);
	border-radius: 0 20px 0 0;
}

.three-box .after-header {
	position: absolute;
	right: 0;
	top: 0;
	width: calc((100% - var(--width)) / 2 - 20px);
	height: 220px;
	background-color: rgba(0,0,0,.04);
}

.three-box .after-header .corner {
	position: absolute;
	left: 0;
	bottom: -30px;
	width: 30px;
	height: 30px;
	background-color: rgba(0,0,0,.05);
}
.three-box .after-header .corner::after {
	display: block;
	content: '';
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--body);
	border-radius: 20px 0 0 0;
}

.three-box .center {
	position: relative;
	width: calc(var(--width) + 40px);
	display: flex;
	justify-content: space-between;
	background-color: rgba(0,0,0,.04);
	border-radius: 0 0 20px 20px;
	padding: 20px;
}


.three-box .header-item {
	width: 30%;
	height: 100%;
}

.three-box .top {
	float: left;
	width: 100%;
	height: 46px;
	background-color: #3EBAD3;
	border-radius: 10px 10px 0 0;
	padding: 0 10px 0 16px;
	display: flex;
	justify-content: space-between;
}

.header-item .top .title {
	width: 50%;
	height: 20px;
	font-size: 15px;
	color: #fff;
	font-weight: 500;
	margin-top: 13px;
}

.header-item .top .title span {
	/* font-weight: 500; */
}

.header-item .top .actions {
	width: 22%;
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
}
.header-item .top button {
	width: 30px;
	height: 30px;
	border-radius: 10px;
	background-color: rgba(0, 0, 0, .3);
	color: #fff;
	font-size: 11px;
}
.header-item .top button:disabled {opacity: .5;}

.header-item .content {
	float: left;
	width: 100%;
	height: calc(100% - 46px);
	background-color: #fff;
	box-shadow: 0 2px 6px rgba(0,0,0,.15);
	border-radius: 0 0 10px 10px;
	padding: 10px;
}

.header-item .content .box {
	position: relative;
	float: left;
	width: 56px;
	height: 56px;
	background-color: #efefef;
	border-radius: 10px;
	margin: 6px 3px;
}

.header-item .content .box a > div {
	position: relative;
	width: 100%;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

@media only screen and (max-width: 500px) {
	.three-box {
		display: block;
		height: auto;
	}

	.header-item .content {height: auto}

	.three-box .center {width: 100%;flex-wrap: wrap;height: auto;}
	.three-box .header-item {width: calc(100% - 16px);margin: 10px 0 0 8px;height: auto;}
	.slide {width: calc(100% - 16px);margin: 10px 0 0 8px;height: 300px;}

	.three-box .free-items .content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}
}
</style>