<template>
	<div class="home">
		<NewsItem />
		<LastAdd />
		<LastMobs />
		<ArtItem/>
		<ValoresItem />
		<RecordItem/>
		<RanksItem/>
	</div>
</template>

<script>
import NewsItem from '../components/home/news.vue'
import LastAdd from '../components/home/last-add.vue'
import LastMobs from '../components/home/last-mobs.vue'
// import EventsItem from '../components/home/events.vue'
import ArtItem from '../components/home/art.vue'
import RecordItem from '../components/home/records.vue'
import RanksItem from '../components/home/ranks.vue'
import ValoresItem from '../components/home/valores.vue'

export default {
	name: 'IndexPage',
	components: {
		NewsItem,
		LastAdd,
		LastMobs,
		ArtItem,
		RecordItem,
		RanksItem,
		ValoresItem
	},
}
</script>

<style scoped>

</style>