<template>
	<div class="item">
		<div class="top">
			<div class="title"><span>Ranking</span> Comentários</div>
			<div class="actions" v-show="rank.length > 0">
				<button :disabled="prev == 0" @click="getRank(prev)"><i class="fa-solid fa-caret-left"></i></button>
				<button :disabled="total < next" @click="getRank(next)"><i class="fa-solid fa-caret-right"></i></button>
			</div>
		</div>
		<div class="content" v-show="rank.length > 0">
			<transition-group name="custom-classes" enter-active-class="animate__animated animate__bounceIn" leave-active-class="animate__animated animate__fadeOut">
				<div class="box" v-for="(r, i) in rank" :key="i">
					<div class="avatar" :style="{backgroundImage: 'url(https://imager.bubblet.com.br/?user='+r.user+'&action=&gesture=std&direction=2&head_direction=3&headonly=false&size=b&img_format=&dance=&effect=&frame_num=30)'}"></div>
					<div class="data">
						<div class="name">{{r.user}}</div>
						<div class="point">{{ r.count }} comentários</div>
					</div>
					<div class="rank" :class="{'gold': r.rank == 1, 'silver': r.rank == 2, 'bronze': r.rank == 3}">{{r.rank }}º</div>
				</div>
			</transition-group>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'RankCom',
	data() {
		return {
			rank: [],
			prev: 0,
			next: 0,
			total: 0,
		}
	},
	methods:{
		async getRank(p=1){
			const req = await axios.post('async/rank-com', {
				pag: p
			})
			this.rank = req.data.rank
			this.prev = req.data.prev
			this.next = req.data.next
			this.total = req.data.total
		}
	},
	mounted(){
		this.getRank()
	}
}
</script>

<style scoped>
.item {width: 32%;}
.item  .top .actions {width: 22%;}
.item .top .title {width: 100%;}

.item .content {
	width: 100%;
	height: auto;
	padding: 10px;
	border-radius: 10px;
	background-color: #2B6070;
	margin-top: 10px;
	display: block;
}

.item .box {
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: rgba(0,0,0,.1);
}
.item .box:first-of-type {border-radius: 10px 10px 0 0;}
.item .box:last-of-type {border-radius: 0 0 10px 10px;}
.item .box:nth-child(2n){background-color: rgba(0,0,0,.2);}

.item .box .avatar {
	width: 60px;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

.item .box .data {
	width: 60%;
	height: auto;
}

.item .box .data > div {
	height: 20px;
	line-height: 20px;
	color: #fff;
}

.item .box .data .name {
	font-weight: 600;
	font-size: 15px;
}

.item .box .data .point {
	font-weight: 300;
	font-size: 13px;
}

.item .box .rank {
	width: 34px;
	height: 34px;
	background-position: 0 -107px;
	background-image: url('../../../assets/img/ranks.png');
	text-align: center;
	line-height: 34px;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
}
.item .box .rank.gold {background-position: 0 0;}
.item .box .rank.silver {background-position: 0 -36px;}
.item .box .rank.bronze {background-position: 0 -72px;}
</style>